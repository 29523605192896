<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        <!-- mdi-dots-vertical -->
        mdi-menu
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.meta.titleBar"
    />

    <v-spacer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      max-width="400"
      min-width="300"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            v-if="notifCount > 0"
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>{{ notifCount }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else>
            mdi-bell
          </v-icon>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
        dense
        flat
      >
        <p class="font-weight-bold text-h4 my-3 mx-3">
          Notifikasi
        </p>

        <v-divider />

        <v-list-item-group
          v-model="model"
          class="list-item-group"
          multiple
        >
          <v-list-item
            v-for="(item, i) in listNotification"
            :key="i"
            two-line
            dense
            :style="getNotificationColor(item)"
            class="py-1"
            @click="handleNotificationClick(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
              <v-list-item-subtitle v-text="item.content" />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-divider />

        <div class="d-flex justify-center">
          <a
            href="#"
            style="text-decoration:none;"
            @click="showAllNotif"
          >
            <p
              class="font-weight-bold text-h5 my-3 mx-3"
              style="color: purple;"
            >
              Tampilkan Semua
            </p>
          </a>
          <div />
        </div>
      </v-list>
    </v-menu>

    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            min-width="0"
            v-bind="attrs"
            text
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="handleButtonClick(item, index)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>

  // Utilities
  import { mapState } from 'vuex'

  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    name: 'DashboardCoreAppBar',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      notifCount: 0,
      notifications: [],
      items: [
        { title: 'Settings' },
        { title: 'Logout' },
      ],
      model: [],
    }),

    computed: {
      ...mapState({
        drawer: state => state.setting.drawer,
      }),

      listNotification () {
        return this.notifications
      },
    },

    created () {
      this.getNotifications()
      setInterval(() => {
        this.getNotifications()
      }, 10000)
    },

    methods: {
      setDrawer (val) {
        this.$store.commit('setting/SET_DRAWER', val)
      },

      handleButtonClick (item, index) {
        const vm = this
        const apiBase = process.env.VUE_APP_API_BASE_URL
        const config = {
          headers: {
            'x-api-key': localStorage.getItem('token'),
          },
        }

        if (index === 0) {
          vm.$router.push({ name: 'Setting' })
        }

        if (index === 1) {
          axios.post(apiBase + '/t/logout', {}, config).then(function (response) {
            if (response.data.status === 200) {
              localStorage.clear()
              this.clearIntervalFunc()
              vm.$router.push({ name: 'Login' })
            }
          }).catch((e) => {
            localStorage.clear()
            this.clearIntervalFunc()
            vm.$router.push({ name: 'Login' })
          })
        }

        // console.log(index)
      },

      clearIntervalFunc () {
        for (let i = 1; i < 99999; i++) {
          window.clearInterval(i)
        }
      },

      showAllNotif () {
        this.$router.push({ name: 'Notification' })
      },

      getNotificationColor (item) {
        if (item.read === false) {
          return 'background-color: #F3E5F5; border-bottom: thin solid rgba(0, 0, 0, 0.12);'
        } else {
          return 'background-color: #FFFFFF; border-bottom: thin solid rgba(0, 0, 0, 0.12);'
        }
      },

      async handleNotificationClick (item) {
        if (item.newtab === 1) {
          await this.setReadSelectedNotification(item)
          window.open(item.link)
        } else {
          this.$router.push({ name: 'Notification' })
        }
      },

      getNotifications () {
        axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
        axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

        const requestBody = {
          get_type: 1,
        }

        axios.post('/g/notification', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.notifCount = res.data.data.new_notif_count
            this.notifications = res.data.data.notification
          }
        }).catch((e) => {

        })
      },

      setReadSelectedNotification (item) {
        const requestBody = {
          read_all: 0,
          notif_id: item.id,
        }

        axios.post('/g/readNotification', requestBody).then((res) => {
          if (res.data.status === 200) {
            // this.$toast.success(res.data.message)
          }
        }).catch((e) => {
          this.$toast.error(e.response.data.error)
        })
      },
    },
  }
</script>

<style scoped>
  .list-item-group {
    max-height: 300px;
    overflow-y: scroll;
  }
</style>
